// Vanity Root URLs: Campaign & Program main pages
import PropTypes from 'prop-types';

import { fetchSlug } from 'lib/vanityUrlHelpers';
import CampaignRoute from '../c/[id]';
import ProgramRoute from '../pr/[id]';
import NotFoundPage from '../404';

const ROUTE_MAP = { Campaign: CampaignRoute, Program: ProgramRoute };

const VanityRootRoute = ({ vanityRecord, ...props }) => {
  if (!vanityRecord) return <NotFoundPage />;
  const RouteComponent = ROUTE_MAP[vanityRecord.type];
  return RouteComponent ? <RouteComponent {...props} /> : null;
};

VanityRootRoute.propTypes = {
  vanityRecord: PropTypes.shape({ type: PropTypes.string.isRequired }),
};

VanityRootRoute.getLayout = (page, router, pageProps) => {
  if (!pageProps.vanityRecord) return page;
  const RouteComponent = ROUTE_MAP[pageProps.vanityRecord.type];
  return RouteComponent?.getLayout?.(page, router, pageProps) ?? page;
};

VanityRootRoute.getInitialProps = async (ctx) => {
  if (ctx.res && ctx.req.headers?.['x-amz-cf-id']) {
    // For CloudFront caching
    if (ctx.req.cookies?.token) {
      // For users with a token, forbid caching
      ctx.res.setHeader('Cache-Control', 'private, no-store, no-cache, must-revalidate');
    } else {
      // For users without a token, allow caching
      ctx.res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=59');
    }
  }

  const vanityRecord = await fetchSlug({ key: ctx.query.slug, scope: 'root' });
  if (!vanityRecord) {
    ctx.res.statusCode = 404;
    return {};
  }

  const RouteComponent = ROUTE_MAP[vanityRecord.type];
  if (!RouteComponent) throw new Error('Unknown vanity URL type');

  ctx.query.id = vanityRecord.objectId;
  const superProps = await RouteComponent.getInitialProps?.(ctx);
  return { vanityRecord, ...superProps };
};

export default VanityRootRoute;
