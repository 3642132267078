import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Image } from 'cloudinary-react';

import config from 'config';
import { formatCurrency } from 'lib/formatters';
import { useProgramPage } from 'context/ProgramPage';
import Button from 'components/common/Button';

const GET_PROGRAM = gql`
  query GetProgram($id: String!) {
    findPrograms(id: $id) {
      id
      amountRaised
      campaignDonateButtonLabel
      campaignHeaderBackgroundImage
      campaignHeadline
      campaignJoinButtonLabel
      heroShowDonate
      heroShowJoin
      heroShowRaised
      heroStyle
      name
    }
  }
`;

const ProgramPageHero = () => {
  const { programId, onJoin, onDonate } = useProgramPage();
  const { data } = useQuery(GET_PROGRAM, { variables: { id: programId } });

  const program = useMemo(() => data?.findPrograms[0], [data]);

  if (!program) return null;

  const amountRaised = formatCurrency(program.amountRaised);
  const actions = [
    program.heroShowDonate && {
      label: program.campaignDonateButtonLabel,
      onClick: () => onDonate(),
    },
    program.heroShowJoin && {
      label: program.campaignJoinButtonLabel,
      onClick: onJoin,
    },
  ].filter(Boolean);

  // Program Style: Cover
  if (program.heroStyle === 'cover') {
    return (
      <header className="relative w-full" style={{ height: '70vh' }}>
        <Image
          publicId={program.campaignHeaderBackgroundImage ?? config('/defaultCampaignHero')}
          alt=""
          className="absolute inset-0 w-full h-full object-center object-cover"
          fetchFormat="auto"
          gravity="faces:auto"
          aspectRatio="4:3"
          crop="fill"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-75" />
        <div className="absolute inset-0 flex justify-center items-center p-6 sm:p-8">
          <div className="text-white text-center" style={{ paddingTop: '5%' }}>
            <h1 className="text-4xl md:text-5xl font-medium leading-tight">
              {program.campaignHeadline ?? program.name}
            </h1>
            {program.heroShowRaised && (
              <p className="mt-5 text-2xl md:text-3xl font-medium">{amountRaised} raised</p>
            )}
            {actions.length > 0 && (
              <div className="flex flex-col md:flex-row justify-center items-center gap-y-3 md:gap-y-0 gap-x-2 mt-6">
                {actions.map(({ label, onClick }, i) => (
                  <Button
                    as="button"
                    type="button"
                    key={label}
                    onClick={onClick}
                    radius="full"
                    color={i === 0 ? 'primary' : 'white'}
                    outline={i === 1}
                    className="font-medium w-full md:w-auto"
                  >
                    {label}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }

  // Program Style: Basic
  return (
    <header className="py-8 lg:py-16">
      <div className="container flex flex-col lg:flex-row-reverse lg:items-center gap-y-8 gap-x-12">
        <div className="lg:grow">
          <Image
            publicId={program.campaignHeaderBackgroundImage ?? config('/defaultCampaignHero')}
            alt=""
            className="block w-full rounded-xl"
            fetchFormat="auto"
            gravity="faces:auto"
            aspectRatio="4:3"
            crop="fill"
          />
        </div>
        <div className="lg:shrink-0 lg:w-1/3">
          <h1 className="text-4xl md:text-5xl font-medium leading-tight">
            {program.campaignHeadline ?? program.name}
          </h1>
          {program.heroShowRaised && (
            <p className="mt-5 text-2xl md:text-3xl font-medium text-gray-600">
              {amountRaised} raised
            </p>
          )}
          {actions.length > 0 && (
            <div className="flex flex-col md:flex-row items-center gap-y-3 md:gap-y-0 gap-x-2 mt-6">
              {actions.map(({ label, onClick }, i) => (
                <Button
                  as="button"
                  type="button"
                  key={label}
                  onClick={onClick}
                  radius="full"
                  color={i === 0 ? 'primary' : 'gray-300'}
                  outline={i === 1}
                  className="font-medium w-full md:w-1/2 xl:min-w-4xs"
                >
                  {label}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

ProgramPageHero.propTypes = {};

ProgramPageHero.defaultProps = {};

export default ProgramPageHero;
