import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useRouter } from 'next/router';

import { useProgramPage } from 'context/ProgramPage';
import CampaignPageCustomBlock from 'components/campaign-page/CampaignPageCustomBlock';
import CampaignPageImpactStories from 'components/campaign-page/CampaignPageImpactStories';
import ProgramPageHero from 'components/program-page/ProgramPageHero';
import ProgramPageCampaignListing from 'components/program-page/ProgramPageCampaignListing';
import CampaignPageLogoGrid from 'components/campaign-page/CampaignPageLogoGrid';

const GET_PROGRAM = gql`
  query GetProgram($id: String!) {
    findPrograms(id: $id) {
      id
      name

      activeFeatures {
        giving
        join
      }

      pages {
        id
        slug
        name
        widgets(order: "order") {
          id
          type
          title
          config
          order
        }
      }
    }
  }
`;

const ProgramPage = () => {
  const router = useRouter();
  const { programId, onDonate, onJoin } = useProgramPage();
  const { data } = useQuery(GET_PROGRAM, { variables: { id: programId } });

  const program = useMemo(() => data?.findPrograms?.[0], [data]);
  const activeFeatures = useMemo(() => program?.activeFeatures, [program]);

  const pageSlug = router.query.page ?? '/';

  const pageSections = (program?.pages ?? []).find((x) => x.slug === pageSlug)?.widgets;

  if (!program) return null;

  return (
    <>
      {pageSlug === '/' && <ProgramPageHero />}
      {pageSections?.map((section) => {
        switch (section.type) {
          case 'customBlock': {
            return <CampaignPageCustomBlock key={section.id} id={section.id} />;
          }

          case 'impactStories': {
            return (
              <CampaignPageImpactStories
                key={section.id}
                id={section.id}
                onDonate={activeFeatures.giving ? onDonate : null}
                onJoin={activeFeatures.join ? onJoin : null}
              />
            );
          }

          case 'childCampaignListing': {
            return <ProgramPageCampaignListing key={section.id} title={section.title} />;
          }

          // To be removed after v1.63
          case 'sponsors': {
            return <CampaignPageLogoGrid key={section.id} id={section.id} />;
          }

          case 'logoGrid': {
            return <CampaignPageLogoGrid key={section.id} id={section.id} />;
          }

          default: {
            return null;
          }
        }
      })}
    </>
  );
};

ProgramPage.propTypes = {};

export default ProgramPage;
