import { useCallback, useMemo, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';

import { setContribution } from './CampaignContribute';

export const ProgramPageContext = createContext();

export const ProgramPageProvider = ({ programId, theme, children }) => {
  const onDonate = useCallback(
    (amount) => {
      if (!!amount) {
        setContribution({ type: 'donation', amount });
        Router.push(`/c/${programId}/contribute/payment`);
        return;
      }
      Router.push(`/c/${programId}/contribute/select`);
    },
    [programId]
  );
  const onJoin = useCallback(() => Router.push(`/pr/${programId}/campaigns`), [programId]);

  const value = useMemo(
    () => ({
      programId,
      theme,
      onDonate,
      onJoin,
    }),
    [programId, theme, onDonate, onJoin]
  );

  return <ProgramPageContext.Provider value={value}>{children}</ProgramPageContext.Provider>;
};

ProgramPageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  programId: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    primaryLight: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    secondaryLight: PropTypes.string.isRequired,
  }).isRequired,
};

export const ProgramPageConsumer = ProgramPageContext.Consumer;

export const useProgramPage = () => useContext(ProgramPageContext);
